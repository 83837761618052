.loading-image{
    height: 120px;
    width: 120px;
	animation: pulse 5s linear infinite;
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

@keyframes pulse {
	0% {
		transform: scale(1.0);
	}
	
	25% {
		transform: scale(1.1);
	}

	50% {
		transform: scale(1.0);
	}
	
	75% {
		transform: scale(0.9);
	}
	
	100% {
		transform: scale(1.0);
	}
}