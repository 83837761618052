body {
  /* font-family: 'averta', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Averta";
  font-weight: normal;
  src: url("../public/fonts/Averta-Regular.otf");
}

.pointer {
  cursor: pointer;
}

.blue {
  color: #407BFF;
}

.bg-white {
  background-color: #fff;
}

* {
  scrollbar-width: 4px !important;
  scrollbar-color: #2CCED2 !important;
  scroll-behavior: smooth !important;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

*::-webkit-scrollbar-track {
  background: white !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #2CCED2 !important;
  border-radius: 10px !important;
  z-index: 999 !important;

}

#root{
  overflow-x: hidden;
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  #root{
    height: auto;
  }
}