.maintenance-image {
    width: 35%;
    height: 45%;
}

@media only screen and (max-width: 600px) {
    .maintenance-image {
        width: 90%;
        height: 45%;
    }
}